










































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import store from '@/services/store';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
import { IUrlFilter, ICurPeriod, GuGkkp, IGuGkkpBase, IGkkpList, IOptions, IAbpBase, IСurComp,
        IRegion, ICurDataType, ICurBudgetVersion, ICurProg, ICurSpec, ICurFormSelect, ICurFunGr,
        IUrlParams, INullSubPrgs } from './components/js/budget-header-new-interfaces'
import BpI18nHandlerMixin from './mixins/bp-i18n-handler-mixin'


@Component({
    name: 'c-budget-req-head',
    components: {
        'multiselect': Multiselect
    }
})


export default class CBudgHeadReq extends BpI18nHandlerMixin {
    @Prop({
        required: false,
        default: true
    })
    private typeReq!: boolean;

    @Prop({
        required: false,
        default: null
    })
    private form!: any | null;

    @Prop({
        required: false,
        default: true
    })
    private guReq!: boolean;

    @Prop({
        required: false,
        default: 'Год'
    })
    private nameYear!: string;

    @Prop({
        required: false,
        default: null
    })
    private yearReq!: boolean; // добавление параметр год year (на выходе yearProp)

    @Prop({
        required: false,
        default: null
    })
    private filter!: any | null;

    @Prop({
        required: false,
        default: null
    })
    private developType!: number| null; // developType для программ (Азамату)

    @Prop({
        required: false,
        default: false
    })
    private openDisabled!: boolean; // disabled кнопка открыть

    @Prop({
        required: false,
        default: 0
    })
    private progDevelopType!: number | null; // develop_type для программ (по умолчанию = 0)

    @Prop({
        required: false,
        default: null
    })
    private currTabName!: string | null;

    private urlFilter: IUrlFilter | null = null;

    // открытие фильтра по ссылке из хлебных крошек
    public openFilterByRef(refName: any, mode: GuGkkp): void {
        this.guGkkpCurr = mode;
        this.openFilter();
        let newRefName = refName
        if (!['curYearRef', 'yearRef', 'gkkpRef'].includes(refName) && this.guGkkpCurr === 'gkkp') {
            newRefName = refName + 'gkkp';
        }
        const refItem: any = this.$refs[newRefName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    public openFilter(): void {
        const drop: any = this.$refs.drop;
        drop.show(true);
    }
    private unwatchPeriod: any = null;
    
    private async created() {
        this.$watch('usrId', async () => { if (this.usrId) {
            this.getGugkkp();
        } });

        this.$watch('guGkkpOptions', async () => { if (this.guGkkpOptions) {
            const isGuUploadingForbidden = this.guGkkpOptions[0].disabled;
            const isGkkpUploadingForbidden = this.guGkkpOptions[1].disabled;
            if (!isGuUploadingForbidden) this.loadAbp();
            if (!isGkkpUploadingForbidden) this.loadAbpGkkp();
        } });

        this.$watch('curPeriod', async () => {
            this.getGugkkp();
            this.loadDataTypeDict();
        });

        this.$watch('curGkkp', function () {
            this.loadCompanyGkkp();
        });

        this.$watch('curAbp', function () {
            this.loadCompany();
            this.loadFuncGR('gu');
        });
        this.$watch('curAbpGkkp', function () {
            this.loadGkkp();
            this.loadFuncGR('gkkp');
        });

        this.$watch('curComp', function () {
            this.getRegionByCodeGu('gu');
        });
        this.$watch('curCompGkkp', function () {
            this.getRegionByCodeGu('gkkp');
        });
        
        this.$watch('region', function () {
            this.loadBudgetVersionDict('gu');
        });
        this.$watch('regionGkkb', function () {
            this.loadBudgetVersionDict('gkkp');
        });

        this.$watch('curBudgetVersion', function () {
            this.loadProg('gu');
            this.loadSpec('gu');
        });
        this.$watch('curBudgetVersionGkkp', function () {
            this.loadProg('gkkp');
            this.loadSpec('gkkp');
        });

        this.$watch('curProg', function () {
            this.loadSubProg('gu');
            this.loadFuncGR('gu');
        });
        this.$watch('curProgGkkp', function () {
            this.loadSubProg('gkkp');
            this.loadFuncGR('gkkp');
        });

        this.$watch('curSpec', function () {
            this.loadFormList('gu');
        });
        this.$watch('curSpecGkkp', function () {
            this.loadFormList('gkkp');
        });
        this.$watch('variantLst', function (newValue, oldValue)
        {   
            if (oldValue && oldValue.length && newValue && newValue[0]?.id !== oldValue[0]?.id) this.onDataTypeChangedGu();
        });
        this.$watch('variantLstGkkp', function (newValue, oldValue)
        {   
            if (oldValue && oldValue.length && newValue && newValue[0]?.id !== oldValue[0]?.id) this.onDataTypeChangedGkkp();
        });
        this.$watch('$i18n.locale', this.setGuGkkpOptionsLang);
        if (this.$route.params && this.$route.params.directLink == 'true'){
            this.componentFirstCreation = true;
            this.getUrl(this.$route.params);     
        } 

        this.getPeriodLst();
        this.unwatchPeriod = this.$watch('curPeriod', this.chgCurPeriod);
    }

    private componentFirstCreation = false;
    private updateFilter = true;
    
    private get lng(): string {
        let lng = 'ru';
        if (this.$i18n.locale === 'kk') lng = 'kk';
        return lng;
    }

    private get localLableField(): string {
        return `lable_name_${this.lng}`;
    }
    private urlChanged(to: any) {
        if (this.$route.params && this.updateFilter) {
            this.$emit('setProgress', 10)
            this.componentFirstCreation = true;
            this.getUrl(to.params);
            this.unwatchPeriod()
            this.getPeriodLst();  
            this.unwatchPeriod = this.$watch('curPeriod', this.chgCurPeriod);
        } 
    }
    
    private get curFormCode(): string {
        let code = '';
        if (this.form) {
            if (this.form.code) code = this.form.code;
            else if (this.form.spf && this.form.spf.spf) code = this.form.spf.spf;
        } 
        return code;
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

// ============= Раздел Плановый период ===============

    private curYear: number | null = null;
    private year: number | null = null;
    private maxYear = 3000;
    private minYear = 2000;

    private periodLst: ICurPeriod[] = [];
    private curPeriod: ICurPeriod | null = null;

    private getPeriodLst(): void {
        const year = (new Date()).getFullYear();
        
        this.periodLst = [];
        const startYear = 2020;
        for (let i = year - startYear + 1; i > 0; i--) {
            this.periodLst.push({ name: `${startYear + i} - ${startYear + i + 2}`, year: startYear + i });
        }
        
        this.setCurrPeriod(year);

        this.curYear = this.curPeriod ? this.curPeriod.year : null;
        if (this.urlFilter && this.urlFilter.yearProp) {
            this.year = this.urlFilter.yearProp;
            this.urlFilter.yearProp = null;
        } else {
            this.year = year + 1;
        }
        if (this.curYear) {
            this.maxYear = this.curYear + 2;
            this.minYear = this.curYear;
        }
    }

    private setCurrPeriod(year: number) {
        if (this.urlFilter && this.urlFilter.year) {
            for (const el of this.periodLst) {
                if (el.year === this.urlFilter.year) {
                    this.curPeriod = el;
                }
            }
            this.urlFilter.year = null;
        } else {
            this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
        }
    }

    private chgCurPeriod(): void {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.year;
            this.year = this.curYear;
            this.maxYear = this.curPeriod.year + 2;
            this.minYear = this.curPeriod.year;
        }
    }

    //================= раздел ГУ/ГККП ==========================
    
    private guGkkpCurr: GuGkkp = 'gu';
    private setGuGkkpOptionsLang(): void {
        this.guGkkpOptions[0].text = this.getLocTitle('gu');
        this.guGkkpOptions[1].text = this.getLocTitle('gkkp');
    }
    private guGkkpOptions: IOptions[] = [
            { text: this.getLocTitle('gu'), value: 'gu', disabled: true },
            { text: this.getLocTitle('gkkp'), value: 'gkkp', disabled: true },
        ];
    private setGuGkkpOptions(): void {
        if (!this.guGkkpBase.hasOwnProperty('linkedGuAmnt') || !this.guGkkpBase.hasOwnProperty('gkkp')) return;
        const guGkkpOptions: IOptions[] = [
            { text: this.getLocTitle('gu'), value: 'gu', disabled: true },
            { text: this.getLocTitle('gkkp'), value: 'gkkp', disabled: true },
        ];
        

        guGkkpOptions[0].disabled = (!this.guGkkpBase.hasOwnProperty('linkedGuAmnt') || this.guGkkpBase.linkedGuAmnt === 0);
        if (this.guGkkpBase.hasOwnProperty('gkkp')) {
            const gkkp = this.guGkkpBase.gkkp!;
            guGkkpOptions[1].disabled = (!gkkp.hasOwnProperty('amnt') || gkkp.amnt === 0);
        }
        if (guGkkpOptions[0].disabled) {
            // если нет привязанных ГУ то активен режим ГККП
            if (!guGkkpOptions[1].disabled) {
                this.guGkkpCurr = 'gkkp';
                // отключение автозагрузки данных формы по прямой ссылке если у юзерп нет прав
                if (this.urlFilter && this.urlFilter.mode === 'gu') this.componentFirstCreation = false;
            } 
            // если нет данных ни по ГУ ни по ГККП будет сообщение
            else if (this.guGkkpBase.hasOwnProperty('linkedGuAmnt') || this.guGkkpBase.hasOwnProperty('gkkp')) {
                this.makeToast('danger', this.getLocTitle('errors.gu_gkkp', 1), this.getLocTitle('errors.gu_gkkp', 2));
                this.componentFirstCreation = false;
            } 
        } else if (guGkkpOptions[1].disabled) {
                this.guGkkpCurr = 'gu';
                // отключение автозагрузки данных формы по прямой ссылке если у юзерп нет прав
                if (this.urlFilter && this.urlFilter.mode === 'gkkp') this.componentFirstCreation = false;
        }
        this.guGkkpOptions = guGkkpOptions;
    }

    // получение данных gu/gkkp
    private guGkkpBase: IGuGkkpBase = {};

    private async getGugkkp(): Promise<void> {
        this.curGkkp = null;
        if (!this.usrId || !this.curYear) { return; }
        try {
            const response = await fetch(`/api-py/get-gu-gkkp-data/${this.usrId}/${this.curYear}`)
            if (response.status === 200) {
                const result: IGuGkkpBase = await response.json();
                this.guGkkpBase = result;
                this.setGuGkkpOptions();
            } else {
                this.guGkkpBase = {};
                this.resetGkkp();
                this.makeToast('danger', this.getLocTitle('errors.gu_gkkp', 1), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.resetGkkp();
            this.guGkkpBase = {};
            this.makeToast('danger', this.getLocTitle('errors.gu_gkkp', 1), (error as Error).toString());
        }        
    }


    // ==================Раздел администратор программ =================
    private abpBase: IAbpBase[] = [];
    private abpBaseWithDoubles: IAbpBase[] = [];
    private curAbp: IAbpBase | null = null;

    private get abp(): IAbpBase[] {
        const res: IAbpBase[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }
        return res;
    }

    // получение справоника АБП
    private async loadAbp(): Promise<void> {
        let result: IAbpBase[] = []
        this.abpBase = [];
        this.abpBaseWithDoubles = [];
        if (!this.usrId || !this.curYear) { return; }
        if (result.length === 0) {
            try {
                const response = await fetch(`/api-py/get-abp-by-user-id/${this.usrId}/${this.curYear}`);
                if (response.status === 200) {
                    result = await response.json();
                } else {
                    this.makeToast('danger', this.getLocTitle('errors.abp'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
                }
            } catch (error) {
                this.makeToast('danger', this.getLocTitle('errors.abp'), (error as Error).toString());
            }
        }
        this.abpBaseWithDoubles = result;
        this.abpBase = this.setIdArr(result, 'abp');

        if (this.abpBase.length > 0) {
            if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.abp) {
                this.curAbp = this.setNameLang(this.findElByCode<IAbpBase>('abp', this.urlFilter.abp, this.abpBase), 'abp');
                if (!this.curAbp) this.badDirectLinkMsg('АБП');
                this.urlFilter.abp = null;
            } else {
                this.curAbp = this.setNameLang(this.abpBase[0], 'abp');
            }
        } else {
            this.curAbp = null;
        }
    }

    //   загрузка абп для гккп
    private abpBaseGkkp: IAbpBase[] = [];
    private abpBaseGkkpWithDoubles: IAbpBase[] = [];
    private curAbpGkkp: IAbpBase | null = null;

    private get abpGkkp(): IAbpBase[] {
        const res: IAbpBase[] = [];
        for (const el of this.abpBaseGkkp) {
            res.push(this.setNameLang(el, 'abp'));
        }
        return res;
    }

    private async loadAbpGkkp(): Promise<void> {
        let result: IAbpBase[] = []
        this.abpBaseGkkp = [];
        this.abpBaseGkkpWithDoubles = [];
        this.curAbpGkkp = null;

        if (result.length === 0) {
            if (!this.usrId || !this.curYear) { return; }
            try {
                const response = await fetch(`/api-py/get-abp-gkkp-by-userid/${this.usrId}/${this.curYear}`);
                if (response.status === 200) {
                   result = await response.json();
                   if (result.length) {
                        this.abpBaseGkkpWithDoubles = result;
                        this.abpBaseGkkp = this.setIdArr(result, 'abp');
                   }
                } else {
                    this.makeToast('danger', `${this.getLocTitle('errors.abp')} ${this.getLocTitle('gkkp')}`, `${this.getLocTitle('errors.err_code')} ${response.status}`);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getLocTitle('errors.abp')} ${this.getLocTitle('gkkp')}`, (error as Error).toString());
            }

            if (this.abpBaseGkkp.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.abp) {
                    this.curAbpGkkp = this.setNameLang(this.findElByCode<IAbpBase>('abp', this.urlFilter.abp, this.abpBaseGkkp), 'abp');
                    if (!this.curAbpGkkp) this.badDirectLinkMsg('АБП');
                    this.urlFilter.abp = null;
                } else {
                    this.curAbpGkkp = this.setNameLang(this.abpBaseGkkp[0], 'abp');
                }
            } else {
                this.curAbpGkkp = null;
            }
        }
    }

    // ======================= Раздел гос учреждение ================
    private companyBase: IСurComp[] = [];
    private curComp: IСurComp | null = null;

    private get company(): IСurComp[] {
        const result: IСurComp[] = [];
        for (const el of this.companyBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // загрузка справочника ГУ 
    private async loadCompany(): Promise<void> {
        this.curComp = null;
        this.companyBase.splice(0);
        if (!this.curAbp) return;
        try {
            const response = await fetch(`/api-py/get-gu-by-userid-abp/${this.usrId}/${this.curYear}/${this.curAbp.abp}`)
            if (response.status === 200) {
                const result: IСurComp[] = await response.json();
                this.companyBase = result;
                if (result.length > 0) {
                    if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.gu) {
                        this.curComp = this.setNameLang(this.findElByCode<IСurComp>('code', this.urlFilter.gu, result), 'code');
                        if (!this.curComp) this.badDirectLinkMsg('Гос. учреждение');
                        this.urlFilter.gu = null;
                    } else {
                        this.curComp = this.setNameLang(result[0], 'code');
                    }
                }
            } else {
                this.makeToast('danger', this.getLocTitle('errors.gu'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.gu'), (error as Error).toString());
        }
    }

    // --------- гос учреждение для ГККП ------------
    private companyBaseGkkp: IСurComp[] = [];
    private curCompGkkp: IСurComp | null = null;

    private get companyGkkp(): IСurComp[] {
        const result: IСurComp[] = [];
        for (const el of this.companyBaseGkkp) {
            result.push(this.setNameLang(el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    private async loadCompanyGkkp(): Promise<void> {
        this.curCompGkkp = null;
        this.companyBaseGkkp.splice(0);
        if (!this.curGkkp || !this.curYear) return;
        try {
            const response = await fetch(`/api-py/get-gu-by-bin/${this.curGkkp.bin}/${this.curYear}`)
            if (response.status === 200) {
                const result: IСurComp[] = await response.json();
                this.companyBaseGkkp = result;
                if (result.length > 0) {
                    if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.gu) {
                        this.curCompGkkp = this.setNameLang(this.findElByCode<IСurComp>('code', this.urlFilter.gu, result), 'code');
                        if (!this.curCompGkkp) this.badDirectLinkMsg('ГККП')
                        this.urlFilter.gu = null;
                    } else {
                        this.curCompGkkp = this.setNameLang(result[0], 'code');
                    }
                }
            } else {
                this.makeToast('danger', `${this.getLocTitle('errors.gu')} ${this.getLocTitle('gkkp')}`, `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', `${this.getLocTitle('errors.gu')} ${this.getLocTitle('gkkp')}`, (error as Error).toString());
        }
    }

    // ===================== Раздел ГККП ===================
    private gkkpBase: IGkkpList[] = [];
    private curGkkp: IGkkpList | null = null;

    private resetGkkp() {
        this.guGkkpBase = {};
        this.curGkkp = null;
    }
    private get gkkp(): IGkkpList[] {
        const result: IGkkpList[] = [];
        for (const el of this.gkkpBase) {
            result.push(this.setNameLang(el, 'bin'));
        }
        return result;

    }

    private async loadGkkp(): Promise<void> {
        this.curGkkp = null;
        this.gkkpBase.splice(0);
        if (!this.curAbpGkkp || !this.curYear || !this.usrId) return;
        try {
            const response = await fetch(`/api-py/get-gkkp-by-abp-user/${this.usrId}/${this.curYear}/${this.curAbpGkkp.abp}`)
            if (response.status === 200) {
                const result: IGkkpList[] = await response.json();
                this.gkkpBase = result;
                if (this.gkkpBase.length > 0) {
                    if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.gkkp) {
                        this.curGkkp = this.setNameLang(this.findElByCode<IGkkpList>('bin', this.urlFilter.gkkp, this.gkkpBase), 'bin');
                        if (!this.curGkkp) this.badDirectLinkMsg('ГККП')
                        this.urlFilter.gkkp = null;
                    } else {
                        this.curGkkp = this.setNameLang(this.gkkpBase[0], 'bin')
                    }
                } else {
                    this.curGkkp = null;
                    // отключение автозагрузки данных формы по прямой ссылке если у юзерп нет прав
                    if (this.urlFilter && this.urlFilter.mode === 'gkkp') this.componentFirstCreation = false;
                }
            } else {
                this.makeToast('danger', this.getLocTitle('errors.gkkp'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.gkkp'), (error as Error).toString());
        }
    }

// =================== Раздел Регион ================
    private region: IRegion | null = null;
    private regionGkkb: IRegion | null = null;
// загрузка справочника регионов 
    private async getRegionByCodeGu(mode: GuGkkp): Promise<void> {
        if (mode === 'gkkp') {
            this.regionGkkb = null;
        } else {
            this.region = null;
        }
        if (mode === 'gu' && !this.curComp) return;
        if (mode === 'gkkp' && !this.curCompGkkp) return;
        const curComp = mode === 'gu' ? this.curComp : this.curCompGkkp;

        if (curComp && curComp.id_region) {
            let result: IRegion[] = [];
            try {
                const response = await fetch('/api-py/get-region-by-code-gu',
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(curComp)
                    })
                if (response.status === 200) {
                    result = await response.json();
                    if (result && result.length > 0 && result[0].code) {
                        if (mode === 'gkkp') {
                            this.regionGkkb = result[0];
                        } else {
                            this.region = result[0];
                        }
                    } 
                } else {
                    this.makeToast('danger', this.getLocTitle('errors.region'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
                }
            } catch (error) {
                result = [];
                this.makeToast('danger', this.getLocTitle('errors.region'), (error as Error).toString());
            }
        }
    }

    // ================= Раздел вид данных =========================
    private dataTypeDictBase: ICurDataType[] = [];
    private curDataType: ICurDataType | null = null;
    private curDataTypeGkkp: ICurDataType | null = null;

    private get dataTypeDict(): ICurDataType[] {
        const result: ICurDataType[] = [];
        for (const el of this.dataTypeDictBase) {
            result.push(this.setNameLang(el));
        }
        return result;
    }

    // Загрузка справочника Видов данных
    private async loadDataTypeDict(): Promise<void> {
        let result: ICurDataType[] = [];
        try {
            const response = await fetch('/api-py/dict_budget_data_types/')
            if (response.status === 200) {
                result = await response.json();
            } else {
                this.makeToast('danger', this.getLocTitle('errors.dt'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            result = [];
            this.makeToast('danger', this.getLocTitle('errors.dt'), (error as Error).toString());
        }
        this.dataTypeDictBase = result;
        if (this.dataTypeDictBase.length > 0) {
            let el: ICurDataType | null = null;
            if (this.urlFilter && this.urlFilter.dataType) {
                el = this.findElByCode<ICurDataType>('code', this.urlFilter.dataType, this.dataTypeDictBase);
                if (!el) this.badDirectLinkMsg('Вид данных');
                this.urlFilter.dataType = null;
            } 
        }
    }

    // =================== Раздел Версия ===================
    // ------------версия бюджета-----------
    private budgetVersionDictBase: ICurBudgetVersion[] = [];
    private budgetVersionDictBaseGkkp: ICurBudgetVersion[] = [];
    private curBudgetVersion: ICurBudgetVersion | null = null;
    private curBudgetVersionGkkp: ICurBudgetVersion | null = null;

    private get variantLst(): ICurBudgetVersion[] {
        if (!this.curDataType || !this.budgetVersionDictBase || !this.budgetVersionDictBase.length) return [];
        const res: ICurBudgetVersion[] = [];
        for (const el of this.budgetVersionDictBase) {
            if (this.curDataType!.code && el.data_type === parseInt(this.curDataType.code)) {
                const newEl = this.setNameLang(this.setCodeText(el, 'variant_uuid'));
                if (newEl) res.push(newEl);
            }
        }
        return res;
    }

    private onDataTypeChangedGu() {
        this.curBudgetVersion = this.setNameLang(this.findCurBudgetVersion(this.variantLst));
    }

    private get variantLstGkkp(): ICurBudgetVersion[] {
        if (!this.curDataTypeGkkp || !this.budgetVersionDictBaseGkkp || !this.budgetVersionDictBaseGkkp.length) return [];
        const res: ICurBudgetVersion[] = [];
        for (const el of this.budgetVersionDictBaseGkkp) {
            if (this.curDataTypeGkkp!.code && el.data_type === parseInt(this.curDataTypeGkkp.code)) {
                const newEl = this.setNameLang(this.setCodeText(el, 'variant_uuid'));
                if (newEl) res.push(newEl);
            }
        }
        return res;
    }

    private onDataTypeChangedGkkp() {
        this.curBudgetVersionGkkp = this.setNameLang(this.findCurBudgetVersion(this.variantLstGkkp));
    }

    // загручка справочника вариантов
    private async loadBudgetVersionDict(mode: GuGkkp): Promise<void> {
        let result: any[] = [];
        if (mode === 'gu') {
            this.budgetVersionDictBase.splice(0);
            this.curBudgetVersion = null;
        } else {
            this.budgetVersionDictBaseGkkp.splice(0);
            this.curBudgetVersionGkkp = null;
        }
        const region = mode === 'gu' ? this.region : this.regionGkkb;
        if (!this.curPeriod || !region) return;
        if (mode === 'gu' && (!this.curComp || !this.curAbp)) return;
        if (mode === 'gkkp' && (!this.curCompGkkp || !this.curAbpGkkp)) return;
 
        const curAbpsList = this.getCurrAbpList(mode)
        const data = {
            guCurr: mode === 'gu' ? this.curComp : this.curCompGkkp,
            curAbpList: curAbpsList,
        }
        try {
            const response = await fetch(`/api-py/get-variants-by-region-year/${region.code}/${this.curPeriod.year}`, 
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                })
            if (response.status === 200) {
                result = await response.json();
                if (result.length === 0 && mode === this.guGkkpCurr) {
                    this.makeToast('warning', this.getLocTitle('errors.varning'), `${this.getLocTitle('errors.variant', 1)} ${mode === 'gu' ? this.getLocTitle('gu') : this.getLocTitle('gkkp')}`);
                }
            } else {
                this.makeToast('danger', this.getLocTitle('errors.variant', 2), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            result = [];
            this.makeToast('danger', this.getLocTitle('errors.variant', 2), (error as Error).toString());
        }
        for (const el of result) {
            el.name_ru = variantNameLangSupport(el, 'ru');
            el.name_kk = variantNameLangSupport(el, 'kk');
            el.name_en = variantNameLangSupport(el, 'en');
        }
        if (mode === 'gu') {
            this.budgetVersionDictBase = JSON.parse(JSON.stringify(result));            
            this.setCurBudgetVersion();
        } else {
            this.budgetVersionDictBaseGkkp = result;
            this.setCurBudgetVersionGkkp();
        }
    }

    private setCurBudgetVersionGkkp() {
        if (this.budgetVersionDictBaseGkkp.length === 0) {
                this.curBudgetVersionGkkp = null;
                this.setCurDataType('gkkp');
                return;
            }
            let el = null;
            if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.variant_uuid) {
                el = this.findElByCode<ICurBudgetVersion>('variant_uuid', this.urlFilter.variant_uuid, this.budgetVersionDictBaseGkkp);
                this.curBudgetVersionGkkp = this.setNameLang(el);
                if (!this.curBudgetVersionGkkp) this.badDirectLinkMsg('Вариант');
                this.urlFilter.variant_uuid = null;
                this.setCurDataType('gkkp');
                return;
            }
            if (this.curBudgetVersionGkkp === null) {
                this.curBudgetVersionGkkp = this.setNameLang(this.findCurBudgetVersion(this.budgetVersionDictBaseGkkp));
            } else {
                let fl = false;
                for (let i=0; i < this.budgetVersionDictBaseGkkp.length; i++) {
                    if (this.budgetVersionDictBaseGkkp[i].variant_uuid === this.curBudgetVersionGkkp.variant_uuid) {
                        fl = true;
                        break
                    }
                }
                if (!fl) { this.curBudgetVersionGkkp = this.setNameLang(this.findCurBudgetVersion(this.budgetVersionDictBaseGkkp)); }
            }
            this.setCurDataType('gkkp');
    }

    private setCurBudgetVersion() {
        if (this.budgetVersionDictBase.length === 0) {
                this.curBudgetVersion = null;
                this.setCurDataType('gu');
                return;
            }
            let el = null;
            if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.variant_uuid) {
                el = this.findElByCode<ICurBudgetVersion>('variant_uuid', this.urlFilter.variant_uuid, this.budgetVersionDictBase);
                this.curBudgetVersion = this.setNameLang(el);
                if (!this.curBudgetVersion) this.badDirectLinkMsg('Вариант');
                this.urlFilter.variant_uuid = null;
                this.setCurDataType('gu');
                return;
            }
            if (this.curBudgetVersion === null) {
                this.curBudgetVersion = this.setNameLang(this.findCurBudgetVersion(this.budgetVersionDictBase));
            } else {
                let fl = false;
                for (let i=0; i < this.budgetVersionDictBase.length; i++) {
                    if (this.budgetVersionDictBase[i].variant_uuid === this.curBudgetVersion.variant_uuid) {
                        fl = true;
                        break
                    }
                }
                if (!fl) { this.curBudgetVersion = this.setNameLang(this.findCurBudgetVersion(this.budgetVersionDictBase)); }
            }
            this.setCurDataType('gu');
    }
    private getCurrAbpList(mode: string): IAbpBase[] {
        if (mode === 'gu') {
            return this.abpBaseWithDoubles.filter((itm: IAbpBase) => itm.abp === this.curAbp!.abp)
        } else {
            return this.abpBaseGkkpWithDoubles.filter((itm: IAbpBase) => itm.abp === this.curAbpGkkp!.abp)
        }
    }

    // определение вида данных для активного варианта
    private setCurDataType(mode: GuGkkp): void {
        if (mode === 'gu' && this.curBudgetVersion && this.dataTypeDictBase) {
            this.curDataType = this.setNameLang(this.dataTypeDictBase.find(itm => itm.code && this.curBudgetVersion && parseInt(itm.code) === this.curBudgetVersion.data_type));
        }
        if (mode === 'gkkp' && this.curBudgetVersionGkkp && this.dataTypeDictBase) {
            this.curDataTypeGkkp = this.setNameLang(this.dataTypeDictBase.find(itm => itm.code && this.curBudgetVersionGkkp && parseInt(itm.code) === this.curBudgetVersionGkkp.data_type));
        }
    }

    // выбор варианта для отображения по умолчанию
    private findCurBudgetVersion(bvDictBase: ICurBudgetVersion[]): ICurBudgetVersion {
        // по умолчанию отображается актуальная версия бюджета
        const actualVariant = bvDictBase.find((itm: ICurBudgetVersion): boolean => {
            if (itm.hasOwnProperty('state') && itm.state === 'actual') return true;
            return false;
        })
        if (actualVariant) return actualVariant;

        // если нет актуальной, то последняя утвержденная версия
        const approvedVariants = bvDictBase
                                    .filter((itm: ICurBudgetVersion): boolean => {
                                        if (itm.hasOwnProperty('state') && itm.state === 'approved') return true;
                                        return false;
                                    })
                                    .sort((a: ICurBudgetVersion, b: ICurBudgetVersion): number => {
                                        if (a.date_ueb && b.date_ueb) {
                                            if (a.date_ueb > b.date_ueb) {
                                                return -1;
                                            }
                                            if (a!.date_ueb < b!.date_ueb) {
                                                return 1;
                                            }
                                        }
                                        // a должно быть равным b
                                        return 0;
                                    })
        if (approvedVariants && approvedVariants.length > 0) return approvedVariants[0];
        
        // в противном случае любая имеющаяся (наибольшая по айди)
        return bvDictBase[0];
    }

    // ================= Раздел Программа ====================
    // ------------------------ программа --------------------------
    private progBase: ICurProg[] = [];
    private curProg: ICurProg | null = null;

    private get prog(): ICurProg[] {
        const res: ICurProg[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        return res;
    }

    private progBaseGkkp: ICurProg[] = [];
    private curProgGkkp: ICurProg | null = null;

    private get progGkkp(): ICurProg[] {
        const res: ICurProg[] = [];
        for (const el of this.progBaseGkkp) {
            res.push(this.setNameLang(el, 'prg'));
        }
        return res;
    }

    // получение справочника программ
    private async loadProg(mode: GuGkkp): Promise<void> {
        let result: ICurProg[] = [];
        if (mode === 'gu') {
            this.progBase.splice(0);
            this.curProg = null;
        } else {
            this.progBaseGkkp.splice(0);
            this.curProgGkkp = null;
        }
        if (result.length === 0) {
            const curAbp = mode === 'gu' ? this.curAbp : this.curAbpGkkp;
            const curBudgetVersion = mode === 'gu' ? this.curBudgetVersion : this.curBudgetVersionGkkp;
            if (!curAbp || !curBudgetVersion) return;
            
            try {
                const response = await fetch(`/api-py/get-programs-by-parent-type-actual/${curAbp.abp}/4/${curBudgetVersion.date_start}/${curBudgetVersion.end_date}`);
                if (response.status === 200) {
                    result = await response.json();
                    if (this.progDevelopType !== null) { result = result.filter((item: any) => item.develop_type === 0); }
                } else {
                    this.makeToast('danger', this.getLocTitle('errors.prg'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
                }
            } catch (error) {
                this.makeToast('danger', this.getLocTitle('errors.prg'), (error as Error).toString());
            }
        }

        if (this.developType !== null) {
            result = result.filter(item => {
                return item.develop_type === this.developType;
            });
        }
        if (mode === 'gu') {
            this.progBase = this.setIdArr(result, 'prg');

            if (this.progBase.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.prg) {
                    this.curProg = this.setNameLang(this.findElByCode<ICurProg>('prg', this.urlFilter.prg, this.progBase), 'prg');
                    if (!this.curProg) this.badDirectLinkMsg('Программу');
                    this.urlFilter.prg = null;
                }
            }
        } else {
            this.progBaseGkkp = this.setIdArr(result, 'prg');

            if (this.progBaseGkkp.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.prg) {
                    this.curProgGkkp = this.setNameLang(this.findElByCode<ICurProg>('prg', this.urlFilter.prg, this.progBaseGkkp), 'prg');
                    if (!this.curProgGkkp) this.badDirectLinkMsg('Программу');
                    this.urlFilter.prg = null;
                }
            }
        }
    }

    // ====================== Раздел подпрограмма ===============

    // ----------------------------подпрограмма------------------------
    private subProgBase: ICurProg[] = [];
    private curSubProg: ICurProg | null | { ppr: null } = null;
    private nullSubProg = {
                abp: null,
                budget_level_id: null,
                develop_type: null,
                end_date: null,
                full_code: null,
                gr: null,
                id: -1,
                is_sequest: null,
                pgr: null,
                ppr: null,
                prg: null,
                short_name_kk: null,
                short_name_ru: null,
                transfer: null,
                type: null,
                name_kk: "0 - null", 
                name_ru: "0 - null", 
                lable_name_kk: "0 - null", 
                lable_name_ru: "0 - null"
            };
    
    private addNullProg(res: ICurProg[]) {
        if (this.isAnyNullSubProgActual) {
            res.unshift(this.nullSubProg);
        }
    }

    private get subProg(): ICurProg[] {
        const res: ICurProg[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        return res;
    }

    private subProgBaseGkkp: ICurProg[] = [];
    private curSubProgGkkp: ICurProg | null | { ppr: null } = null;

    private get subProgGkkp(): ICurProg[] {
        const res: ICurProg[] = [];
        for (const el of this.subProgBaseGkkp) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        return res;
    }

    // Загрузка подпрограммы
    private async loadSubProg(mode: GuGkkp) {
        let result: ICurProg[] = [];
        if (mode === 'gu') {
            this.subProgBase.splice(0);
            this.curSubProg = null;
        } else {
            this.subProgBaseGkkp.splice(0);
            this.curSubProgGkkp = null;
        }
        const curAbp = mode === 'gu' ? this.curAbp : this.curAbpGkkp;
        const curProg = mode === 'gu' ? this.curProg : this.curProgGkkp;
        const curBudgetVersion = mode === 'gu' ? this.curBudgetVersion : this.curBudgetVersionGkkp;
        if (!curAbp || !curBudgetVersion || !curProg) return;
        
        await this.loadNullSubProg(curAbp, curProg);

        try {
            const response = await fetch(`/api-py/get-subprograms-by-abp-prg/${curAbp.abp}/${curProg.prg}/${curBudgetVersion.date_start}/${curBudgetVersion.end_date}`);
            if (response.status === 200) { 
                result = await response.json();
            } else {
                this.makeToast('danger', this.getLocTitle('errors.ppr'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.ppr'), (error as Error).toString());
            result = [];
        }

        let isPprExist = this.urlFilter && !!this.urlFilter.ppr;
        if (this.isAnyNullSubProgActual) {
            isPprExist = this.urlFilter && (!!this.urlFilter.ppr || this.urlFilter.ppr === null);
        }

        if (mode === 'gu') {
            this.subProgBase = this.setIdArr(result, 'ppr');
            this.addNullProg(this.subProgBase);

            if (this.subProgBase.length > 0) {
                
                if (this.urlFilter && this.urlFilter.mode === 'gu' && isPprExist) {
                    this.curSubProg = this.setNameLang(this.findElByCode<ICurProg>('ppr', this.urlFilter.ppr, this.subProgBase), 'ppr');
                    if (!this.curSubProg) this.badDirectLinkMsg('Подпрограмму');
                    this.urlFilter.ppr = undefined;
                }
            }
        } else {
            this.subProgBaseGkkp = this.setIdArr(result, 'ppr');
            this.addNullProg(this.subProgBaseGkkp);

            if (this.subProgBaseGkkp.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gkkp' && isPprExist) {
                    this.curSubProgGkkp = this.setNameLang(this.findElByCode<ICurProg>('ppr', this.urlFilter.ppr, this.subProgBaseGkkp), 'ppr');
                    if (!this.curSubProgGkkp) this.badDirectLinkMsg('Подпрограмму');
                    this.urlFilter.ppr = undefined;
                }
            }
        }
        if (this.componentFirstCreation) this.chgData();
    }

    // ============ раздел Специфика ================
    // --- Загрузка Специфики
    private specBase: ICurSpec[] = [];
    private curSpec: ICurSpec | null = null;

    private get spec(): ICurSpec[] {
        const res: ICurSpec[] = [];
        for (const el of this.specBase) {
            res.push(this.setNameLang(el, 'spf'));
        }
        return res;
    }

    private specBaseGkkp: ICurSpec[] = [];
    private curSpecGkkp: ICurSpec | null = null;

    private get specGkkp(): ICurSpec[] {
        const res: ICurSpec[] = [];
        for (const el of this.specBaseGkkp) {
            res.push(this.setNameLang(el, 'spf'));
        }
        return res;
    }

    private async loadSpec(mode: GuGkkp) {
        let result: ICurSpec[] = [];
        if (mode === 'gu') {
            this.specBase.splice(0);
            this.curSpec = null;
        } else {
            this.specBaseGkkp.splice(0);
            this.curSpecGkkp = null;
        }

        const curBudgetVersion = mode === 'gu' ? this.curBudgetVersion : this.curBudgetVersionGkkp;
        if (!curBudgetVersion) return;
        try {
            const response = await fetch(`/api-py/get-specific-actual/${curBudgetVersion.date_start}/${curBudgetVersion.end_date}`);
            if (response.status === 200) { 
                result = await response.json();
            } else {
                this.makeToast('danger', this.getLocTitle('errors.spf'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.spf'), (error as Error).toString());
            result = [];
        }
        if (result.length === 0) return;
        if (mode === 'gu') {
            this.specBase = this.setIdArr(result, 'spf');

            if (this.specBase.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.spf) {
                    this.curSpec = this.setNameLang(this.findElByCode<ICurSpec>('spf', this.urlFilter.spf, this.specBase), 'spf');
                    if (!this.curSpec) this.badDirectLinkMsg('Специфику');
                    this.urlFilter.spf = null;
                }
            }
        } else {
            this.specBaseGkkp = this.setIdArr(result, 'spf');

            if (this.specBaseGkkp.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.spf) {
                    this.curSpecGkkp = this.setNameLang(this.findElByCode<ICurSpec>('spf', this.urlFilter.spf, this.specBaseGkkp), 'spf');
                    if (!this.curSpecGkkp) this.badDirectLinkMsg('Специфику');
                    this.urlFilter.spf = null;
                }
            }
        }
    }

    // ============== Раздел Форма ================
    private curFormSelect: ICurFormSelect | null = null;
    private curFormSelectGkkp: ICurFormSelect | null = null;

    private get formDict(): ICurFormSelect[] {
        const result: ICurFormSelect[] = [];
        for (let i = 0; i < this.formList.length; i++) {
            const obj = this.setNameLang(this.formList[i], 'code');
            obj.id = i;
            result.push(obj);
        }
        if (result.length === 1) this.curFormSelect = result[0];
        return result;
    }

    private get formDictGkkb(): ICurFormSelect[] {
        const result: ICurFormSelect[] = [];
        for (let i = 0; i < this.formListGkkp.length; i++) {
            const obj = this.setNameLang(this.formListGkkp[i], 'code');
            obj.id = i;
            result.push(obj);
        }
        if (result.length === 1) this.curFormSelectGkkp = result[0];
        return result;
    }
    // получения списка форм по спф
    private formList: ICurFormSelect[] = [];
    private formListGkkp: ICurFormSelect[] = [];

    private async loadFormList(mode: GuGkkp) {
        let result: ICurFormSelect[] = [];
        if (mode === 'gu') {
            this.formList.splice(0);
            this.curFormSelect = null;
        } else {
            this.formListGkkp.splice(0);
            this.curFormSelectGkkp = null;
        }
        
        const curBudgetVersion = mode === 'gu' ? this.curBudgetVersion : this.curBudgetVersionGkkp;
        const curSpec = mode === 'gu' ? this.curSpec : this.curSpecGkkp;
        if (!curBudgetVersion || !curSpec) return;
        try {
            const response = await fetch(`/api-py/get-forms-by-spf/${curSpec.spf}/${curBudgetVersion.date_start}/${curBudgetVersion.end_date}`);
            if (response.status === 200) { 
                result = await response.json();
            } else {
                this.makeToast('danger', this.getLocTitle('errors.form'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.form'), (error as Error).toString());
            result = [];
        }
        if (result.length === 0) return;
        if (mode === 'gu') {
            this.formList = this.setIdArr(result, 'code');
            if (this.formList.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.form) {
                    this.curFormSelect = this.setNameLang(this.findElByCode<ICurFormSelect>('code', this.urlFilter.form, this.formList), 'code');
                    if (!this.curFormSelect) this.badDirectLinkMsg('Форму');
                    this.urlFilter.form = null;
                }
            }
        } else {
            const filteredResult = result.filter((itm: any) => !['02-159_1', '02-159_2'].includes(itm.code))
            this.formListGkkp = this.setIdArr(filteredResult, 'code');

            if (this.formListGkkp.length > 0) {
                if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.form) {
                    this.curFormSelectGkkp = this.setNameLang(this.findElByCode<ICurFormSelect>('code', this.urlFilter.form, this.formListGkkp), 'code');
                    if (!this.curFormSelectGkkp) this.badDirectLinkMsg('Форму');
                    this.urlFilter.form = null;
                }
            }
        }
        if (this.componentFirstCreation) this.chgData();
    }

    private customName(value: null | string | undefined): string {
        if (!value) return '';
        if (value === '01-144-sm') return `01-144 ${this.getDuplDataText('sm')}`;
        if (value === '02-159_1') return `02-159 (${this.getDuplDataText('roads')})`;
        if (value === '02-159_2') return '02-159 (339)';
        return value
    }

    // ====================== Раздел функциональная группа ===============
    private curFunGr: ICurFunGr | null = null;
    private curFunGrGkkp: ICurFunGr | null = null;

    // / Получение ФГ
    private async loadFuncGR(mode: GuGkkp) {
        let result: ICurFunGr[] = [];
        if (mode === 'gu') {
            this.curFunGr = null;
        } else {
            this.curFunGrGkkp = null;
        }
        const curBudgetVersion = mode === 'gu' ? this.curBudgetVersion : this.curBudgetVersionGkkp;
        const curAbp = mode === 'gu' ? this.curAbp : this.curAbpGkkp;
        const curProg = mode === 'gu' ? this.curProg : this.curProgGkkp;
        if (!curBudgetVersion || !curAbp || !curProg) return;
        
        try {
            const response = await fetch(`/api-py/get-func-gr-by-abp-prg/${curAbp.abp}/${curProg.prg}/${curBudgetVersion.date_start}/${curBudgetVersion.end_date}`);
            if (response.status === 200) { 
                result = await response.json();
            } else {
                this.makeToast('danger', this.getLocTitle('errors.gr'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.gr'), (error as Error).toString());
            result = [];
        }
        if (result.length > 0) {
            if (mode === 'gu') {
                if (this.urlFilter && this.urlFilter.mode === 'gu' && this.urlFilter.gr) {
                    this.curFunGr = this.findElByCode<ICurFunGr>('gr', this.urlFilter.gr, result);
                    this.urlFilter.gr = null;
                } else {
                    this.curFunGr = this.setNameLang(result[0], 'gr');
                }
            } else {
                if (this.urlFilter && this.urlFilter.mode === 'gkkp' && this.urlFilter.gr) {
                    this.curFunGrGkkp = this.findElByCode<ICurFunGr>('gr', this.urlFilter.gr, result);
                    this.urlFilter.gr = null;
                } else {
                    this.curFunGrGkkp = this.setNameLang(result[0], 'gr');
                }
            }
        }
        if (this.componentFirstCreation) this.chgData();
    }

    // ============== Получение диапазона нулевых подпрограмм ================
    private nullSubPrograms: INullSubPrgs[] = []; 

    // Загрузка пустых подпрограмм
    private async loadNullSubProg(curAbp: IAbpBase, curProg: ICurProg) {
        try {
            const response = await fetch(`/api-py/get-null-subprograms-by-abp-prg/${curAbp.abp}/${curProg.prg}`);
            if (response.status === 200) { 
                this.nullSubPrograms = await response.json();
            } else {
                this.makeToast('danger', this.getLocTitle('errors.null_ppr'), `${this.getLocTitle('errors.err_code')} ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', this.getLocTitle('errors.null_ppr'), (error as Error).toString());
        }
    }

    private get isAnyNullSubProgActual(): Boolean {
        let isAnyNullSubProgActual = false;
        
        const curBudgetVersion = this.guGkkpCurr === 'gu' ? this.curBudgetVersion : this.curBudgetVersionGkkp;
        if (!this.nullSubPrograms.length || !curBudgetVersion) return isAnyNullSubProgActual;
        const curBudgetVersionStartDate = curBudgetVersion.date_start;
        const curBudgetVersionEndDate = curBudgetVersion.end_date;
        let tempVar = 1;
        for (const nullSubProg of this.nullSubPrograms) {
        tempVar += 1;
            const isNullSubProgBegDateActual = (!nullSubProg.beg_date || nullSubProg.beg_date <= curBudgetVersionEndDate!) 
            const isNullSubProgEndDateActual = (!nullSubProg.end_date || nullSubProg.end_date >= curBudgetVersionStartDate!);
            isAnyNullSubProgActual = isNullSubProgBegDateActual && isNullSubProgEndDateActual;
            if (isAnyNullSubProgActual) break;
        }

        return isAnyNullSubProgActual;
    }

    // =================== Открытие по прямой ссылке =================
    private getUrl(params: IUrlParams) {
        if (params.curYear === undefined) {
            this.urlFilter = null;
            return;
        }
        const newUrlFilter: IUrlFilter = {
            abp: null,
            dataType: null,
            form: null,
            gkkp: null,
            gr: null,
            gu: null,
            mode: null,
            ppr: null,
            prg: null,
            spf: null,
            variant_uuid: null,
            year: null,
            yearProp: null
        };

        if (params.mode !== 'false') {
            if (params.mode !== 'null') {
                newUrlFilter.mode = params.mode as GuGkkp;
                if (newUrlFilter.mode) {
                    this.guGkkpCurr = newUrlFilter.mode;
                }
            }
        }

        if (params.gkkp !== 'false') {
            if (params.gkkp !== 'null') {
                newUrlFilter.gkkp = params.gkkp;
            }
        }

        
        if (params.year !== 'false') {
            if (params.year !== 'null') {
                newUrlFilter.yearProp = parseInt(params.year);
            }
        }
        if (params.dataType !== 'false') {
            if (params.dataType !== 'null') {
                newUrlFilter.dataType = params.dataType;
            }
        }
        
        if (params.gr !== 'false') {
            if (params.gr !== 'null') {
                newUrlFilter.gr = parseInt(params.gr);
            }
        }
        if (params.abp !== 'false') {
            if (params.abp !== 'null') {
                newUrlFilter.abp = parseInt(params.abp);
            }
         }
        if (params.prg !== 'false') {
            if (params.prg !== 'null') {
                newUrlFilter.prg = parseInt(params.prg);
            }
        }
        if (params.ppr !== 'false') {
            if (params.ppr !== 'null') {
                newUrlFilter.ppr = parseInt(params.ppr);
            }
            if (params.ppr === 'null') {
                newUrlFilter.ppr = null;
            }
        }
        if (params.gu !== 'false') {
            if (params.gu !== 'null') {
                newUrlFilter.gu = params.gu;
            }
        }
        if (params.variant_uuid !== 'false') {
            if (params.variant_uuid !== 'null') {
                newUrlFilter.variant_uuid = params.variant_uuid;
            }
        }
        if (params.spf !== 'false') {
            if (params.spf !== 'null') {
                newUrlFilter.spf = parseInt(params.spf);
            }
        }
        if (this.curFormCode !== '') {
            newUrlFilter.form = this.curFormCode;
        } 

        if (params.curYear !== 'false') {
            if (params.curYear !== 'null') {
                newUrlFilter.year = parseInt(params.curYear);
            }
        }

        this.urlFilter = newUrlFilter;
    }

    private badDirectLinkMsg(text: string) {
        if (this.urlFilter) {
            for (const key in this.urlFilter) {
                this.urlFilter[key] = null;
            }
        }
        this.setGuGkkpOptions();
    }


    // =================== прочие сервисы =========================
    private findElByCode<Type>(codeName: string, code: any, arr: any[]): Type | null {
        let isCodeEmpty = code === null;
        if (this.isAnyNullSubProgActual) {
            isCodeEmpty = code === undefined;
        }
        if (isCodeEmpty) { return null; }
        for (const el of arr) {
            if (el[codeName] === code) {
                return el;
            }
        }
        return null;
    }

    private setNameLang(obj: any, codeName?: string): any {
        if (!obj) { return null; }
        let txt_ru = obj['name_ru'];
        let txt_kk = obj['name_kk'];

        const isCodeNameExist = codeName !== undefined && codeName !== null && obj[codeName] !== undefined;
        const isNullSupProg = codeName === 'ppr' && obj.id === -1;
        if (!isNullSupProg && isCodeNameExist) {
            const customName = this.customName(obj[codeName!]);
            txt_ru = customName + ' - ' + txt_ru;
            txt_kk = customName + ' - ' + txt_kk;
        }
        const el = Object.assign({}, obj);
        el.lable_name_ru = txt_ru;
        el.lable_name_kk = txt_kk;
        return el;
    }

    private setCodeText(obj: any | null, codeName?: string): ICurBudgetVersion | null {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = txt + ' [' + obj[codeName] + ']';
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private makeToast(variant: string, title: string, tostbody: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    // валидация поля Год. Разрешены только значения в пределах периода
    private yearValidation(value: string, minYear: number, maxYear: number): void {
        if (!value) { this.year = minYear; return; };
        const intVal = parseInt(value);
        if (intVal < minYear) { this.year = minYear; return; };
        if (intVal > maxYear) { this.year = maxYear; return; };
        this.year = intVal;
    }

    //  ===================== Кнопка Открыть ==================
    private chgData(): void {
        if ((this.guGkkpCurr === 'gu' && !this.curAbp) || (this.guGkkpCurr === 'gkkp' && !this.curAbpGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('abp')} ${this.getLocTitle('errors.not_chosen', 1)}`);
            return;
        }
        // ГУ не выбрано
        if ((this.guGkkpCurr === 'gu' && !this.curComp) || (this.guGkkpCurr === 'gkkp' && !this.curCompGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('gu')} ${this.getLocTitle('errors.not_chosen', 0)}`);
            return;
        }
        // ГККП не выбрано
        if (this.guGkkpCurr === 'gkkp' && !this.curGkkp) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('gkkp')} ${this.getLocTitle('errors.not_chosen', 0)}`);
            return;
        }
        // регион не выбран
        if ((this.guGkkpCurr === 'gu' && !this.region) || (this.guGkkpCurr === 'gkkp' && !this.regionGkkb)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('region')} ${this.getLocTitle('errors.not_chosen', 1)}`);
            return;
        }
        // Тип данных не выбран
        if ((this.guGkkpCurr === 'gu' && !this.curDataType) || (this.guGkkpCurr === 'gkkp' && !this.curDataTypeGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('dt')} ${this.getLocTitle('errors.not_chosen', 1)}`);
            return;
        }
        // Версия не выбрана
        if ((this.guGkkpCurr === 'gu' && !this.curBudgetVersion) || (this.guGkkpCurr === 'gkkp' && !this.curBudgetVersionGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('variant')} ${this.getLocTitle('errors.not_chosen', 2)}`);
            return;
        }
        // программа не выбрана
        if ((this.guGkkpCurr === 'gu' && !this.curProg) || (this.guGkkpCurr === 'gkkp' && !this.curProgGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('prg')} ${this.getLocTitle('errors.not_chosen', 2)}`);
            return;
        }
        // подпрограмма не выбрана
        const isSubProgNotSelected = (this.guGkkpCurr === 'gu' && !this.curSubProg) || (this.guGkkpCurr === 'gkkp' && !this.curSubProgGkkp);
        if (isSubProgNotSelected) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('ppr')} ${this.getLocTitle('errors.not_chosen', 2)}`);
            return;
        }
        // специфика не выбрана
        if ((this.guGkkpCurr === 'gu' && !this.curSpec) || (this.guGkkpCurr === 'gkkp' && !this.curSpecGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('spf')} ${this.getLocTitle('errors.not_chosen', 2)}`);
            return;
        }

        // функц группа не выбрана
        if ((this.guGkkpCurr === 'gu' && !this.curFunGr) || (this.guGkkpCurr === 'gkkp' && !this.curFunGrGkkp)) {
            return;
        }        
        // Форма не выбрана
        if ((this.guGkkpCurr === 'gu' && !this.curFormSelect) || (this.guGkkpCurr === 'gkkp' && !this.curFormSelectGkkp)) {
            if (!this.componentFirstCreation) this.makeToast('danger', this.getLocTitle('errors.attention'), `${this.getLocTitle('form')} ${this.getLocTitle('errors.not_chosen', 2)}`);
            return;
        }
        const curAbpsList = this.getCurrAbpList(this.guGkkpCurr)
        const data = this.guGkkpCurr === 'gu' ? 
            { form: this.curFormSelect, year: this.curYear, yearProp: this.year, dataType: this.curDataType, gr: this.curFunGr, abp: this.curAbp, prg: this.curProg, ppr: this.curSubProg!.ppr ? this.curSubProg!.ppr : null, gu: this.curComp, spf: this.curSpec, region: this.region!.code, budgetVersion: this.curBudgetVersion, guList: this.companyBase,  gkkp: 'no', mode: this.guGkkpCurr, curAbpList: curAbpsList } 
            : { form: this.curFormSelectGkkp, year: this.curYear, yearProp: this.year, dataType: this.curDataTypeGkkp, gr: this.curFunGrGkkp,  abp: this.curAbpGkkp, prg: this.curProgGkkp, ppr: this.curSubProgGkkp!.ppr ? this.curSubProgGkkp!.ppr : null, gu: this.curCompGkkp,  spf: this.curSpecGkkp, region: this.regionGkkb!.code, budgetVersion: this.curBudgetVersionGkkp, guList: this.companyBaseGkkp,  gkkp: this.curGkkp!.bin, mode: this.guGkkpCurr, curAbpList: curAbpsList };
        if (!data) return;

        if (!this.componentFirstCreation) this.chgCurFormSelect(data);
        
        const isActualFormComponentUploaded = this.curFormCode === data!.form!.code;
        
        if (isActualFormComponentUploaded) this.$emit('chgData', data);
        
        const drop: any = this.$refs.drop;
        drop.hide(true);
        this.componentFirstCreation = false;
    }

    private chgCurFormSelect(data: any) {
        this.updateFilter = false;
        const formCode = ['02-159_1', '02-159_2'].includes(data.form.code) ? data.form.code.replace('_', '-') : data.form.code
        let params = `#/form${formCode}/${data.year}/${data.yearProp}/${data.dataType.code}/${data.gr.gr}/${data.abp.abp}/${data.prg.prg}/${data.ppr}/${data.gu.code}/${data.budgetVersion.variant_uuid}/${data.spf.spf}/${data.gkkp}/${data.mode}/`;
        params = params + 'true/end'
        params = params + this.getCurrTabName(formCode, data.mode);
        document.location.assign(params);
        this.updateFilter = true;
    }

    private getCurrTabName(formCode: string, mode: string): string {
        if (this.currTabName && mode === 'gkkp' && formCode === this.curFormCode) {
            return '/' + this.currTabName;
        }
        return '';
    }

    private getLocTitle(field: string, id: any = -1): any {
        const mainTextFieldName = "modules.budget_request.filter." + field;
        if (id >= 0) return this.$tc(mainTextFieldName, id);
        return this.$t(mainTextFieldName);
    }
}
